<template>
    <v-container fluid>
        <div class="md:tw-w-1/2 lg:tw-w-1/3 mx-auto">
            <v-card>
                <v-card-title>Make Payment</v-card-title>
                <v-card-text>
                    <v-text-field
                        type="text"
                        label="Reference/Username/Invoice ID"
                        v-model="form.reference"
                        :error="form.errors.has('reference')"
                        :error-messages="form.errors.get('reference')"
                        @keydown="getPayableAmount"
                        autofocus
                        :loading="gettingPayableAmount"
                    />
                    <div v-show="doneGettingPayableAmount">
                        <v-text-field
                            label="Amount"
                            v-model="totalAmount"
                            :error="form.errors.has('amount')"
                            :error-messages="form.errors.get('amount')"
                            @keydown="form.errors.clear('amount')"
                            v-currency
                            ref="totalAmount"
                        />
                        <v-text-field
                            type="text"
                            label="Card number"
                            autofocus
                            v-model="form.credit_card_number"
                            :error="form.errors.has('credit_card_number')"
                            :error-messages="form.errors.get('credit_card_number')"
                            @keydown="form.errors.clear('credit_card_number')"
                            required
                            ref="number"
                        />
                        <div class="tw-grid tw-gap-3 tw-grid-cols-2">
                            <v-text-field
                                type="text"
                                label="Expiration date"
                                v-model="form.credit_card_expiry"
                                :error="form.errors.has('credit_card_exp_month')"
                                :error-messages="form.errors.get('credit_card_exp_month')"
                                @keydown="form.errors.clear('credit_card_exp_month')"
                                required
                                ref="expiry"
                            />
                            <v-text-field
                                type="text"
                                label="CVC"
                                v-model="form.credit_card_cvc"
                                :error="form.errors.has('credit_card_cvc')"
                                :error-messages="form.errors.get('credit_card_cvc')"
                                @keydown="form.errors.clear('credit_card_cvc')"
                                required
                                ref="cvc"
                            />
                        </div>
                        <v-text-field
                            type="text"
                            label="Card name"
                            v-model="form.credit_card_name"
                            :error="form.errors.has('credit_card_name')"
                            :error-messages="form.errors.get('credit_card_name')"
                            @keydown="form.errors.clear('credit_card_name')"
                            required
                        />
                        <v-text-field
                            type="email"
                            label="Email"
                            v-model="form.email"
                            :error="form.errors.has('email')"
                            :error-messages="form.errors.get('email')"
                            @keydown="form.errors.clear('email')"
                        />
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        block
                        color="primary"
                        @click="pay"
                        :loading="paying"
                        :disabled="disablePayButton"
                        >Pay {{ totalAmount }}</v-btn
                    >
                </v-card-actions>
            </v-card>
            <div class="text-center mt-4" v-if="!authenticated">
                Have an account? <router-link to="/login">Sign in</router-link>
            </div>
        </div>
    </v-container>
</template>
<script>
import Payment from 'payment';
import { mapGetters } from 'vuex';
import Http from '@/utils/httpClient';
import Form from '@/utils/form';
import debounce from 'lodash.debounce';

    export default {
        data() {
            return {
                paying: false,
                gettingPayableAmount: false,
                doneGettingPayableAmount: false,
                totalAmount: 0,
                form: new Form({
                    reference: null,
                    amount: null,
                    credit_card_number: null,
                    credit_card_exp_month: null,
                    credit_card_cvc: null,
                    credit_card_name: null,
                    email: null,
                }),
            }
        },
        computed: {
            ...mapGetters(['authenticated']),
            disablePayButton() {
                const { totalAmount } = this.$refs;

                if (!totalAmount || !this.form.credit_card_number) {
                    return true;
                }

            if (!totalAmount || !this.form.credit_card_number) {
                return true;
            }

            return (
                totalAmount &&
                (this.$ci.getValue(totalAmount) === 0 || this.$ci.getValue(totalAmount) === null)
            );
        }
    },
    watch: {
        form: {
            handler() {
                if (this.doneGettingPayableAmount) {
                    this.$nextTick(() => {
                        Payment.formatCardNumber(this.$refs.number.$el.querySelector('input'), 16);
                        Payment.formatCardExpiry(this.$refs.expiry.$el.querySelector('input'));
                        Payment.formatCardCVC(this.$refs.cvc.$el.querySelector('input'));
                    });

                    if (this.form.credit_card_expiry) {
                        const expiry = this.form.credit_card_expiry.split(' / ');

                        this.form.set({
                            credit_card_exp_month: expiry[0],
                            credit_card_exp_year: expiry[1]
                        });
                    }
                }
            },
            deep: true
        }
    },
    methods: {
        pay() {
            this.$root.$loader.open({ text: 'Processing...' });
            this.createStripePaymentMethod();
        },
        processPayment() {
            Http.post('quick_payment', this.form.payload())
                .then(() => {
                    this.form.reset();
                    this.newCardForm.reset();
                    this.$refs.stripeCard.clearableCallback();
                    this.$root.$loader.close();
                    this.$root.$snackbar.open({
                        text:
                            'Payment successful! A copy of your receipt has been sent to your email.',
                        type: 'success',
                        timeout: 0
                    });
                })
                .catch(({ response }) => {
                    this.$root.$loader.close();
                    this.form.errors.record(response.data);
                });
        },
        createStripePaymentMethod() {
            this.$refs.stripeCard.stripe
                .createPaymentMethod({
                    type: 'card',
                    card: this.$refs.stripeCard.card,
                    billing_details: {
                        name: this.form.cardHolderName
                    }
                })
                .then(({ error, paymentMethod }) => {
                    if (!error) {
                        this.form.set({
                            paymentMethod: paymentMethod.id
                        });

                        this.processPayment();
                    } else {
                        this.$root.$loader.close();
                    }
                });
        },
        getPayableAmount: debounce(function () {
            this.gettingPayableAmount = true;
            this.doneGettingPayableAmount = false;
            this.$ci.setValue(this.$refs.totalAmount, 0);
            Http.get('payable_amount', { params: { reference: this.form.reference } })
                .then(({ data }) => {
                    this.gettingPayableAmount = false;
                    this.doneGettingPayableAmount = true;
                    this.$ci.setValue(this.$refs.totalAmount, data.amount);
                })
                .catch(() => {
                    this.gettingPayableAmount = false;
                });
        }, 1000)
    }
};
</script>
